import React from "react";
import { Link } from "gatsby";

const BlogWidgetPosts = ({ data, dataPage, parentSlug }) => {
	return data?.nodes
		?.filter((item) => item.id !== dataPage.id)
		.map((item, index) => (
			<Link
				to={`/${parentSlug}/${item.slug}/`}
				className="post__widget-item"
				key={index}
			>
				<div>
					<img
						className="img-fluid"
						src={item?.featuredImage?.node?.sourceUrl}
						alt={item.title}
					/>
					<span>
						{item.date.split("T")[0].split("-").reverse().join(".")}
					</span>
				</div>
				<p>{item.title}</p>
			</Link>
		));
};

export default BlogWidgetPosts;
