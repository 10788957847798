import "styles/pages/page-post.scss";

import React from "react";
import { Link, graphql } from "gatsby";

import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import BlogWidgetCategory from "components/BlogWidgets/BlogWidgetCategory";
import BlogWidgetPosts from "components/BlogWidgets/BlogWidgetPosts";

const Post = ({ pageContext, data }) => {
	const dataPage = pageContext.data;

	return (
		<Layout headerColor="#0260DB">
			<Seo
				title={dataPage.seo.title ? dataPage.seo.title : dataPage.title}
				description={dataPage.seo.metaDesc && dataPage.seo.metaDesc}
			/>
			<SubpageHeader
				image={require("assets/images/single-blog-bg-main.jpg").default}
				borderColor="#0260DB"
			/>

			<section className="post">
				<div className="container">
					<div className="row align-items-end">
						<div className="col-xl-7">
							<h2 className="section-title">{dataPage.title}</h2>
						</div>

						<div className="col-xl-5">
							<div className="post__info">
								{dataPage.author.node.avatar && (
									<span className="post__author-thumb"></span>
								)}
								<strong>Autor:</strong>
								<span>{dataPage.author.node.firstName}</span>
								<span className="post__info-shape"></span>
								<strong>Data:</strong>
								{dataPage.date
									.split("T")[0]
									.split("-")
									.reverse()
									.join(".")}
							</div>
						</div>
					</div>

					<div className="post__content">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8">
								<div
									dangerouslySetInnerHTML={{
										__html: dataPage.content,
									}}
								/>
								<div className="post__nav">
									{pageContext.next && (
										<Link
											to={`/blog/${pageContext.next.slug}/`}
											className="button button--yellow"
										>
											Poprzedni post
										</Link>
									)}
									{pageContext.prev && (
										<Link
											to={`/blog/${pageContext.prev.slug}/`}
											className="button button--yellow"
										>
											Następny post
										</Link>
									)}
								</div>
							</div>

							<div className="col-xl-3 col-lg-4">
								<div className="post__widget">
									<h4>Zobacz wszystkie newsy w kategorii</h4>
									<BlogWidgetCategory
										data={data}
										pageContext={pageContext}
									/>
									<BlogWidgetPosts
										data={data?.allPosts}
										dataPage={dataPage}
										parentSlug="blog"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export const query = graphql`
	query {
		allPosts: allWpPost(limit: 5) {
			nodes {
				id
				slug
				uri
				title
				date
				featuredImage {
					node {
						sourceUrl
					}
				}
			}
		}
		allCategories: allWpCategory(
			filter: { name: {}, slug: { ne: "uncategorized" } }
			sort: { fields: name, order: ASC }
		) {
			nodes {
				slug
				name
			}
		}
	}
`;

export default Post;
