import React from "react";
import { navigate } from "gatsby";

const BlogWidgetCategory = ({ data, pageContext }) => {
	return (
		<div className="post__widget-select">
			<select
				onChange={(e) => navigate(`/blog/${e.target.value}/`)}
				defaultValue={pageContext?.data?.categories?.nodes[0]?.slug}
			>
				{data?.allCategories.nodes.map((item, index) => (
					<option value={item.slug} key={index}>
						{item.name}
					</option>
				))}
			</select>
		</div>
	);
};

export default BlogWidgetCategory;
